import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';

// appsolut base components
import ContentElement from '../elements/ContentElement';
import StyledBox from '../../components/controls/StyledBox';

function Content({ header, title, featured, featuredLabel, teaser, body, footer }) {
  return (
    <StyledBox component="section">
      <Container>
        <Grid container>
          <Grid item xs={12} lg={9} mx="auto">
            <ContentElement
              header={header}
              title={title}
              featured={featured}
              featuredLabel={featuredLabel}
              teaser={teaser}
              body={body}
              footer={footer}
            />
          </Grid>
        </Grid>
      </Container>
    </StyledBox>
  );
}

Content.defaultProps = {
  featured: false,
  featuredLabel: 'Für Sie ausgewählt',
};

Content.propTypes = {
  header: PropTypes.string,
  title: PropTypes.string,
  featured: PropTypes.bool,
  featuredLabel: PropTypes.string,
  teaser: PropTypes.string,
  body: PropTypes.string,
  footer: PropTypes.string,
};

export default Content;
