//============================================================
// appsolut Theme VIENNA - v1.0.0
//============================================================
//
// Based on Otis Kit PRO - v2.0.1 from www.creative-tim.com
//
//============================================================

import React from 'react';
import PropTypes from 'prop-types';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';

// Otis Kit PRO components
import StyledBox from '../../../../controls/StyledBox';
import StyledTypography from '../../../../controls/StyledTypography';

function DefaultDownloadCard({ image, alt, title, link, filename, size }) {
  return (
    <Card sx={{ mt: 3 }}>
      <a href={link} target="_blank" rel="noopener noreferrer" download>
        <Grid container>
          <Grid item xs={3} sx={{ mt: 0 }}>
            <StyledBox width="100%" pt={2} pb={1} px={2}>
              <StyledBox component="img" src={image} alt={alt} width="100%" />
            </StyledBox>
          </Grid>
          <Grid item xs={9} sx={{ my: 'auto' }}>
            <StyledBox pt={{ xs: 1, lg: 2.5 }} pb={2.5} pr={4} pl={{ xs: 4, lg: 1 }} lineHeight={1}>
              <StyledTypography variant="body1">{title}</StyledTypography>
              <StyledTypography component="p" variant="caption" color="text" mt={1}>
                {filename}
              </StyledTypography>
              <StyledTypography component="p" variant="caption" color="text" mt={1}>
                {size}
              </StyledTypography>
            </StyledBox>
          </Grid>
        </Grid>
      </a>
    </Card>
  );
}

// Typechecking props for the DefaultDownloadCard
DefaultDownloadCard.propTypes = {
  image: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  filename: PropTypes.string.isRequired,
  size: PropTypes.number.isRequired,
};

export default DefaultDownloadCard;
