import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';

// appsolut base components
import StyledBox from '../../components/controls/StyledBox';
import StyledTypography from '../../components/controls/StyledTypography';
import DownloadCard from '../elements/DownloadCard';

function Downloads({ header, downloads = [] }) {
  const hasDownloads = downloads && downloads.length > 0;

  return (
    <Fragment>
      {hasDownloads && (
        <StyledBox component="section" py={{ xs: 1, md: 2 }}>
          <Container>
            <Grid container>
              {header && (
                <Grid item xs={12} lg={9} mx="auto">
                  <StyledBox mb={2}>
                    <StyledTypography variant="h5">{header}</StyledTypography>
                  </StyledBox>
                </Grid>
              )}
              <Grid item xs={12} lg={9} mx="auto">
                <Grid container spacing={3}>
                  {downloads.map((download) => (
                    <Grid item key={download.id} xs={12} lg={6}>
                      <StyledBox>
                        <DownloadCard key={download.id} download={download} />
                      </StyledBox>
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            </Grid>
          </Container>
        </StyledBox>
      )}
    </Fragment>
  );
}

Downloads.propTypes = {
  header: PropTypes.string,
  downloads: PropTypes.array,
};

export default Downloads;
