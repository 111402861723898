import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import readableBytes from 'readable-bytes';

// appsolut base components
import StyledBox from '../../components/controls/StyledBox';
import BaseDownloadCard from '../../components/shapes/Cards/DownloadCards/DefaultDownloadCard';

const emptyImage =
  'https://res.cloudinary.com/appsolut365/image/upload/v1658741075/appsolut365/webportal/default/document_jc3coa.png';

function DownloadCard({ download }) {
  const { title, basename, alt, url, customData, size, filename } = download;

  const imageSrc = customData && customData.bild ? customData.bild : emptyImage;
  let cardTitle = title ? title : alt;
  cardTitle = cardTitle ? cardTitle : basename;
  const cardAlt = alt ? alt : cardTitle;
  const fileNameStrg = `Datei: ${filename}`;
  const sizeStrg = size ? `Größe: ${readableBytes(size, 2)}` : `--`;

  return (
    <StyledBox mt={3}>
      <BaseDownloadCard
        image={imageSrc}
        alt={cardAlt}
        title={cardTitle}
        link={url}
        filename={fileNameStrg}
        size={sizeStrg}
      />
    </StyledBox>
  );
}

DownloadCard.propTypes = {
  download: PropTypes.instanceOf(Object).isRequired,
};

export default DownloadCard;
